import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle } from '../components/Section'


const PageTitle = styled.h1`
  text-align:center;
`

function PrivacyplicyPage() {
  return (
    <Layout pageName="privacy-policy">
      <SEO title="Privacy Policy" />
      <Section pt="150px" pb="150px" xpt="100px" xpb="60px" mpb="40px" mpt="80px">
        <div className="container">
            <BreadCrumb>
              <Link to="/">Home</Link><Link to="/#">Privacy Policy</Link>
            </BreadCrumb>
            <MarkdownContentWrapper>
              <MarkdownContentWrapperInner>
                <MarkdownContentTitle>
                  <PageTitle>Privacy Policy</PageTitle>
                </MarkdownContentTitle>
                <MarkdownContent>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                </MarkdownContent>
                <MarkdownContent>
                  <h2>Heading</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                  <ul>
                    <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                    <li>Lorem Ipsum is simply dummy text of the printing and type setting industry.</li>
                    <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                    <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                    <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                  </ul>
                </MarkdownContent>
                <MarkdownContent>
                  <h2>Heading</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>

                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                </MarkdownContent>
                <MarkdownContent>
                  <h2>Heading</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                </MarkdownContent>
                <MarkdownContent>
                  <h2>Heading</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                  <ul>
                    <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                    <li>Lorem Ipsum is simply dummy text of the printing and type setting industry.</li>
                    <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                    <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                    <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                  </ul>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                </MarkdownContent>
              </MarkdownContentWrapperInner>
            </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}

export default PrivacyplicyPage
